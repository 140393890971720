import mokaCoin from "./resources/mf_logo_tr.png";
import logo from "./resources/mf_banner.png";

const App = () => {
  return (
    <div className="pattern-bg bg-mk-graygreen min-h-screen p-8 flex flex-col items-center justify-center gap-6">
      <img src={logo} alt="Moka Finance" className="w-80" />
      <p className="text-xl font-extrabold uppercase mb-8 -mt-8">
        yet another tomb fork
      </p>
      <MokaCoin />
      <p className="text-xl sm:text-4xl text-center font-extrabold uppercase mt-8 text-mk-gray">
        The only stable coin
        <br />
        pegged to BTC on the Avalanche chain
      </p>
      <p className="text-xl sm:text-4xl text-center font-extrabold uppercase mt-4">
        Take MOKA
        <br />
        and enjoy some nice coffe
      </p>
    </div>
  );
};

const MokaCoin = () => (
  <div className="coin-container my-6">
    <div className="coin">
      <div className="coin__edge"></div>
      <div className="coin__side coin__side--front">
        <img className="coin__image" src={mokaCoin} alt="Moka Coin" />
      </div>
      <div className="coin__side coin__side--back">
        <img className="coin__image" src={mokaCoin} alt="Moka Coin" />
      </div>
    </div>
  </div>
);

export default App;
